import React, { useState, useEffect } from 'react'
import { DeviceIdShower } from 'presentation/components/AuthenticationLayout/DeviceIdShower'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Alert,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { cookies } from 'scripts/cookies'
import { getEnduserTotpTwoFactorAuthentication } from 'api/getEnduserTotpTwoFactorAuthentication'
import { postEnduserTotpTwoFactorAuthentication } from 'api/postEnduserTotpTwoFactorAuthentication'
import { getPasswordPolicy } from 'api/getPasswordPolicy'
import { patchEnduserPasswordUpdateBySelf } from 'api/patchEnduserPasswordUpdateBySelf'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { PasswordPolicy } from 'types/ApiResponse'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { patchEnduserPinCodeUpdateBySelf } from 'api/patchEnduserPinCodeUpdateBySelf'

interface Props {
  deviceId: string | undefined
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}

const defaultPasswordPolicy: PasswordPolicy = {
  minimum_length: 6,
  maximum_length: 50,
  uppercase_required: false,
  lowercase_required: false,
  number_required: false,
  symbol_required: false,
  not_allow_last_password: false,
}

export const FooterLarge: React.FC<Props> = ({
  deviceId,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  const [open, setOpen] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [pinCodeModalOpen, setPinCodeModalOpen] = useState(false) // 追加
  const [authCode, setAuthCode] = useState('')
  const [sessionToken, setSessionToken] = useState('')
  const [qrCode, setQrCode] = useState('')
  const [otpSecret, setOtpSecret] = useState('')
  const [alreadyEnabled, setAlreadyEnabled] = useState(false)
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy>(
    defaultPasswordPolicy,
  )
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [pinCode, setPinCode] = useState('')
  const [confirmPinCode, setConfirmPinCode] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPincode, setShowNewPincode] = useState(false)
  const [openSnackbar] = useSnackbar()
  const sessionTokenFromCookie = cookies.get('session_token')

  useEffect(() => {
    if (sessionTokenFromCookie) {
      setSessionToken(sessionTokenFromCookie)
    }
  }, [sessionTokenFromCookie])

  const handleClickOpen = async () => {
    const { data, status } = await getEnduserTotpTwoFactorAuthentication(
      sessionToken,
    )
    if (status === 200) {
      const { qr_code, otp_secret, already_enabled } = data
      setQrCode(qr_code)
      setOtpSecret(otp_secret)
      setAlreadyEnabled(already_enabled)
    }
    setOpen(true)
  }

  const handlePasswordModalOpen = () => {
    // forcePasswordChangeがtrueの場合はユーザー操作で開く必要はない
    if (!forcePasswordChange) {
      setPasswordModalOpen(true)
    }
  }

  const handlePasswordModalClose = () => {
    // forcePasswordChangeがtrueなら閉じられないようにする
    if (forcePasswordChange) return
    setPasswordModalOpen(false)
  }

  const handlePinCodeModalOpen = () => {
    // forcePinCodeChangeがtrueの場合はユーザー操作で開く必要はない
    if (!forcePinCodeChange) {
      setPinCodeModalOpen(true)
    }
  }

  const handlePinCodeModalClose = () => {
    // forcePinCodeChangeがtrueなら閉じられないようにする
    if (forcePinCodeChange) return
    setPinCodeModalOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAuthCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthCode(event.target.value)
  }

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value)
  }

  const handlePinCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 5) {
      setPinCode(event.target.value)
    }
  }

  const handleConfirmPinCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length < 5) {
      setConfirmPinCode(event.target.value)
    }
  }

  const handleSubmit = async () => {
    // Authenticatorコードの処理を追加
    const { status } = await postEnduserTotpTwoFactorAuthentication(
      sessionToken,
      otpSecret,
      authCode,
    )
    if (status === 200) {
      openSnackbar('二段階認証が設定されました')
      setAuthCode('')
      setOpen(false)
    } else {
      openSnackbar('二段階認証の設定に失敗しました')
    }
  }

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      openSnackbar('パスワードが一致しません')
      return
    }

    const sessionTokenFromCookie = (await cookies.get(
      'session_token',
    )) as string
    setSessionToken(sessionTokenFromCookie)

    const { status } = await patchEnduserPasswordUpdateBySelf(
      sessionTokenFromCookie,
      newPassword,
    )
    if (status === 200) {
      openSnackbar('パスワードが更新されました')
      setNewPassword('')
      setConfirmPassword('')
      setPasswordModalOpen(false)
    } else {
      openSnackbar('パスワードの更新に失敗しました')
    }
  }

  const handlePinCodeSubmit = async () => {
    // 追加
    if (pinCode !== confirmPinCode) {
      openSnackbar('PINコードが一致しません')
      return
    }

    if (!/^\d{4}$/.test(pinCode)) {
      openSnackbar('PINコードは4桁の数字である必要があります')
      return
    }

    const sessionTokenFromCookie = cookies.get('session_token') as string
    setSessionToken(sessionTokenFromCookie)

    const { status } = await patchEnduserPinCodeUpdateBySelf(
      sessionTokenFromCookie,
      pinCode,
    )

    if (status === 200) {
      openSnackbar('PINコードが更新されました')
      setPinCode('')
      setConfirmPinCode('')
      setPinCodeModalOpen(false)
    } else {
      openSnackbar('PINコードの更新に失敗しました')
    }
  }

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword)

  const toggleShowNewPincode = () => setShowNewPincode(!showNewPincode)

  useEffect(() => {
    ;(async () => {
      if (open) {
        const sessionTokenFromCookie = (await cookies.get(
          'session_token',
        )) as string
        setSessionToken(sessionTokenFromCookie)

        const { data, status } = await getEnduserTotpTwoFactorAuthentication(
          sessionToken,
        )
        if (status === 200) {
          const { qr_code, otp_secret, already_enabled } = data
          setQrCode(qr_code)
          setOtpSecret(otp_secret)
          setAlreadyEnabled(already_enabled)
        }
      }
    })()
  }, [open, sessionToken])

  useEffect(() => {
    ;(async () => {
      try {
        const subdomain = getSubdomain(window.location.host)
        const response = await getPasswordPolicy(subdomain)
        if (response && response.password_policy) {
          setPasswordPolicy(response.password_policy)
        } else {
          console.error('Password policy not found, using default values')
          setPasswordPolicy(defaultPasswordPolicy)
        }
      } catch (error) {
        console.error('Error fetching password policy:', error)
        setPasswordPolicy(defaultPasswordPolicy)
      }
    })()
  }, [])

  // forcePasswordChange/forcePinCodeChange が true の場合、自動的にパスワード変更モーダルを開く
  useEffect(() => {
    // 意図的にpasswordを後に開く。両方とも要求される場合は、パスワードを優先する
    if (forcePinCodeChange) {
      setPinCodeModalOpen(true)
    }
    if (forcePasswordChange) {
      setPasswordModalOpen(true)
    }
  }, [forcePasswordChange, forcePinCodeChange])

  return (
    <div className="pb-1 mt-8 absolute bottom-0" style={{ width: '100%' }}>
      <div className="flex justify-center mb-4">
        <DeviceIdShower deviceId={deviceId}></DeviceIdShower>
      </div>
      <div style={{ height: 20 }} className="justify-center flex">
        <span
          className="text-xs align-text-bottom"
          style={{ color: '#828282', cursor: 'pointer' }}
          onClick={handleClickOpen}
        >
          Onetap, Inc &copy {new Date().getFullYear()} | 認証アプリの設定
        </span>
        <span
          className="text-xs align-text-bottom ml-1"
          style={{ color: '#828282', cursor: 'pointer' }}
          onClick={handlePasswordModalOpen}
        >
          | パスワードを更新する
        </span>
        <span
          className="text-xs align-text-bottom ml-1"
          style={{ color: '#828282', cursor: 'pointer' }}
          onClick={handlePinCodeModalOpen} // 修正
        >
          | PINコードを更新する
        </span>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>認証アプリを設定する</DialogTitle>
        <DialogContent>
          <div
            className="w-full"
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              paddingTop: 5,
              paddingBottom: 15,
            }}
          >
            {alreadyEnabled && (
              <Alert severity="info" sx={{ width: '100%' }}>
                二段階認証は設定済みです
              </Alert>
            )}
          </div>
          <Typography>
            二段階認証に利用する認証アプリの初期設定ができます。
            <br />
            表示されたQRコードをアプリで読み取り、表示される6桁の数字を入力してください。
          </Typography>
          <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
            <img src={qrCode} />
          </div>
          <TextField
            label={
              alreadyEnabled
                ? '既に設定されています'
                : '6桁の数字を入力してください'
            }
            variant="outlined"
            fullWidth
            value={authCode}
            onChange={handleAuthCodeChange}
            disabled={alreadyEnabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={alreadyEnabled}
          >
            送信する
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={pinCodeModalOpen}
        onClose={handlePinCodeModalClose}
        disableEscapeKeyDown={forcePinCodeChange}
      >
        <DialogTitle>PINコードを更新する</DialogTitle>
        <DialogContent>
          <Typography>4桁の数字で新しいPINコードを設定できます。</Typography>
          <TextField
            label="新しいPINコード"
            variant="outlined"
            fullWidth
            value={pinCode}
            onChange={handlePinCodeChange}
            style={{ marginTop: 20 }}
            type={showNewPincode ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowNewPincode}
                    edge="end"
                  >
                    {showNewPincode ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="確認用のPINコード"
            variant="outlined"
            fullWidth
            value={confirmPinCode}
            onChange={handleConfirmPinCodeChange}
            style={{ marginTop: 20 }}
            type={showNewPincode ? 'text' : 'password'}
          />
        </DialogContent>
        <DialogActions>
          {
            // forcePinCodeChange が true の場合、PINコード変更モーダルを閉じるボタンを表示しない
            !forcePinCodeChange && (
              <Button onClick={handlePinCodeModalClose} color="primary">
                キャンセル
              </Button>
            )
          }
          <Button onClick={handlePinCodeSubmit} color="primary">
            更新する
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={passwordModalOpen}
        onClose={handlePasswordModalClose}
        disableEscapeKeyDown={forcePasswordChange}
      >
        <DialogTitle>パスワードを更新する</DialogTitle>
        <DialogContent>
          <Typography>
            次のルールにしたがって、パスワードを再設定することができます。
            <br />
            <br />
            <>
              ・最小文字数： {passwordPolicy.minimum_length}文字 <br />
            </>
            <>
              ・最大文字数： {passwordPolicy.maximum_length}文字 <br />
            </>
            {passwordPolicy.lowercase_required ? (
              <>
                ・少なくとも小文字を1文字使う
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.uppercase_required ? (
              <>
                ・少なくとも大文字を1文字使う
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.number_required ? (
              <>
                ・少なくとも数字を1文字使う
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.symbol_required ? (
              <>
                ・記号を1文字以上使う（! @ # $ % ^ & * _）
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.not_allow_last_password ? (
              <>
                ・直前のパスワードは再利用できません
                <br />
              </>
            ) : (
              <></>
            )}
          </Typography>
          <TextField
            label="新しいパスワード"
            variant="outlined"
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={handleNewPasswordChange}
            style={{ marginTop: 20 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="確認用のパスワード"
            variant="outlined"
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            style={{ marginTop: 20 }}
          />
        </DialogContent>
        <DialogActions>
          {
            // forcePasswordChange が true の場合、パスワード変更モーダルを閉じるボタンを表示しない
            !forcePasswordChange && (
              <Button onClick={handlePasswordModalClose} color="primary">
                キャンセル
              </Button>
            )
          }
          <Button onClick={handlePasswordSubmit} color="primary">
            更新する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import React, { FC } from 'react'
import { NoAppLarge } from './Large'
import { NoAppSmall } from './Small'

interface Props {
  windowWidth: number
  teamName: string | undefined
  userName: string | undefined
  deviceId: string | undefined
  canUseAccessRequest: boolean
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}
export const NoApp: FC<Props> = ({
  windowWidth,
  teamName,
  userName,
  deviceId,
  canUseAccessRequest,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  if (windowWidth < 1000) {
    return (
      <NoAppSmall
        teamName={teamName}
        userName={userName}
        deviceId={deviceId}
        canUseAccessRequest={canUseAccessRequest}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      />
    )
  } else {
    return (
      <NoAppLarge
        teamName={teamName}
        userName={userName}
        deviceId={deviceId}
        canUseAccessRequest={canUseAccessRequest}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      />
    )
  }
}

import React, { FC } from 'react'
import { HeaderSmall } from 'presentation/pages/apps/Common/Header/Small/header'
import { FooterSmall } from 'presentation/pages/apps/Common/Footer/Small'
import { NoApp } from '../Common/Main'
interface Props {
  teamName: string | undefined
  userName: string | undefined
  deviceId: string | undefined
  canUseAccessRequest: boolean
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}

export const NoAppSmall: FC<Props> = ({
  teamName,
  userName,
  deviceId,
  canUseAccessRequest,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  return (
    <div
      data-test="noAppSmall"
      className="flex justify-center"
      style={{ background: '#E5E5E5', minHeight: '100vh', width: '100%' }}
    >
      <HeaderSmall
        teamName={teamName}
        userName={userName}
        canUseAccessRequest={canUseAccessRequest}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      ></HeaderSmall>
      <NoApp></NoApp>
      <FooterSmall deviceId={deviceId}></FooterSmall>
    </div>
  )
}

import { HeaderSmall } from 'presentation/pages/apps/Common/Header/Small/header'
import { FooterSmall } from 'presentation/pages/apps/Common/Footer/Small'
import { AppSmall } from 'presentation/pages/apps/AppExists/Small/AppSmall'
import React, { FC, useMemo } from 'react'
import { AppWithSortedIndexWithIdentityExistsType } from 'types/App'
import { AppListHeaderSmall } from './AppListHeaderSmall'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { getCanUserWriteIdentity } from 'scripts/getCanUserWriteIdentity'
interface Props {
  teamName: string | undefined
  userName: string | undefined
  canUseAccessRequest: boolean
  deviceId: string | undefined
  apps: AppWithSortedIndexWithIdentityExistsType[]
  chromeExtensionInstalled: boolean
  openInNewTabWhenAccessingAppInEnduserPortal: boolean | undefined
  setApps: React.Dispatch<
    React.SetStateAction<AppWithSortedIndexWithIdentityExistsType[] | undefined>
  >
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}
export const AppExistsSmall: FC<Props> = ({
  teamName,
  userName,
  deviceId,
  apps,
  chromeExtensionInstalled,
  openInNewTabWhenAccessingAppInEnduserPortal,
  canUseAccessRequest,
  setApps,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  const [showMenu, setShowMenu] = React.useState(false)
  const handleClick = () => {
    setShowMenu(!showMenu)
  }
  const showSettingButton = useMemo(() => {
    return apps.some((app) => {
      return app.allow_enduser_password_view || getCanUserWriteIdentity(app)
    })
  }, [apps])
  return (
    <div
      className="relative"
      style={{
        background: '#E5E5E5',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <HeaderSmall
        teamName={teamName}
        userName={userName}
        canUseAccessRequest={canUseAccessRequest}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      ></HeaderSmall>
      <div className="w-full h-24" />
      <div className="mx-auto" style={{ maxWidth: 320 }}>
        <Box display="flex">
          <AppListHeaderSmall />
          <Box flex={1} />
          {showSettingButton ? (
            <Button
              data-testid="toggle-show-app-item-menu-button"
              size={'small'}
              sx={{ marginRight: 1.5 }}
              onClick={handleClick}
              variant="outlined"
              color="inherit"
            >
              {showMenu ? '閉じる' : '設定'}
            </Button>
          ) : null}
        </Box>
        <div className="flex flex-wrap justify-start content-around">
          {apps
            .sort((a, b) => a.sorted_index - b.sorted_index)
            .map((app, i) => {
              return (
                <AppSmall
                  key={app.id}
                  app={app}
                  isExtensionInstalled={chromeExtensionInstalled}
                  openInNewTabWhenAccessingAppInEnduserPortal={
                    openInNewTabWhenAccessingAppInEnduserPortal
                  }
                  setApps={setApps}
                  appIndex={i}
                  apps={apps}
                  showMenu={showMenu}
                />
              )
            })}
        </div>
      </div>
      <div className="w-full h-24" />
      <FooterSmall deviceId={deviceId}></FooterSmall>
    </div>
  )
}

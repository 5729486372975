import React, { FC } from 'react'
import { HeaderLarge } from 'presentation/pages/apps/Common/Header/Large'
import { FooterLarge } from 'presentation/pages/apps/Common/Footer/Large'
import { NoApp } from '../Common/Main'
interface Props {
  teamName: string | undefined
  userName: string | undefined
  deviceId: string | undefined
  canUseAccessRequest: boolean
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}

export const NoAppLarge: FC<Props> = ({
  teamName,
  userName,
  deviceId,
  canUseAccessRequest,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  return (
    <div
      data-test="noAppLarge"
      className="flex justify-center flex-wrap"
      style={{ background: '#E5E5E5', height: '100vh' }}
    >
      <HeaderLarge
        canSortApps={false}
        teamName={teamName}
        userName={userName}
        canUseAccessRequest={canUseAccessRequest}
      ></HeaderLarge>
      <NoApp></NoApp>
      <FooterLarge
        deviceId={deviceId}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      ></FooterLarge>
    </div>
  )
}

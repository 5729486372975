import React, { FC, useEffect, useState } from 'react'
import { AppWithSortedIndexWithIdentityExistsType } from 'types/App'
import { HeaderLarge } from 'presentation/pages/apps/Common/Header/Large'
import { FooterLarge } from 'presentation/pages/apps/Common/Footer/Large'
import { AppList, LARGE_APP_LIST_MAX_WIDTH } from './AppList'
import { Dialog, Box, Button, Alert } from '@mui/material'
import { GetEnduserResponse } from 'types/ApiResponse'
import { AppIconObj } from '../..'

interface Props {
  teamName: string | undefined
  userName: string | undefined
  canUseAccessRequest: boolean
  deviceId: string | undefined
  apps: AppWithSortedIndexWithIdentityExistsType[]
  chromeExtensionInstalled: boolean
  openInNewTabWhenAccessingAppInEnduserPortal: boolean | undefined
  setApps: React.Dispatch<
    React.SetStateAction<AppWithSortedIndexWithIdentityExistsType[] | undefined>
  >
  appIconObj: AppIconObj
  handleChangeAppIconSize: (size: 'large' | 'medium' | 'small') => Promise<void>
  canSortApps: boolean
  handleChangeCanSortApps: (canSortApps: boolean) => void
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}
export const AppExistsLarge: FC<Props> = ({
  teamName,
  userName,
  canUseAccessRequest,
  deviceId,
  apps,
  chromeExtensionInstalled,
  openInNewTabWhenAccessingAppInEnduserPortal,
  setApps,
  appIconObj,
  handleChangeAppIconSize,
  canSortApps,
  handleChangeCanSortApps,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  const [showAppMenu, setShowAppMenu] = React.useState(false)
  const handleToggleShowAppMenu = () => {
    setShowAppMenu(!showAppMenu)
  }
  return (
    <>
      <div className="relative min-h-screen" style={{ background: '#E5E5E5' }}>
        <HeaderLarge
          teamName={teamName}
          userName={userName}
          canUseAccessRequest={canUseAccessRequest}
          handleChangeAppIconSize={handleChangeAppIconSize}
          handleToggleShowAppMenu={handleToggleShowAppMenu}
          handleChangeCanSortApps={handleChangeCanSortApps}
          canSortApps={canSortApps}
        ></HeaderLarge>
        <div
          className="w-full"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: 150,
            paddingBottom: 5,
          }}
        >
          {canSortApps && (
            <Alert
              severity="info"
              sx={{ width: '100%', maxWidth: LARGE_APP_LIST_MAX_WIDTH }}
            >
              ドラッグ＆ドロップでアイコンの並べ替えができます。並べ替え中はアプリをクリックしてもログインできません。
            </Alert>
          )}
        </div>
        <AppList
          apps={apps}
          isExtensionInstalled={chromeExtensionInstalled}
          openInNewTabWhenAccessingAppInEnduserPortal={
            openInNewTabWhenAccessingAppInEnduserPortal
          }
          setApps={setApps}
          appIconObj={appIconObj}
          handleToggleShowAppMenu={handleToggleShowAppMenu}
          showAppMenu={showAppMenu}
          canSortApps={canSortApps}
        ></AppList>
        <div className="w-full h-32" />
        <FooterLarge
          deviceId={deviceId}
          forcePasswordChange={forcePasswordChange}
          forcePinCodeChange={forcePinCodeChange}
        ></FooterLarge>
      </div>
    </>
  )
}

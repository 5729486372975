import React, { useState, useEffect } from 'react'
import LockedLogo from 'images/lockedlogo.png'
import { cookies } from 'scripts/cookies'
import axios from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { navigate } from 'gatsby'
import { LogoutResponse, PasswordPolicy } from 'types/ApiResponse'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { getPasswordPolicy } from 'api/getPasswordPolicy'
import { patchEnduserPasswordUpdateBySelf } from 'api/patchEnduserPasswordUpdateBySelf'
import { patchEnduserPinCodeUpdateBySelf } from 'api/patchEnduserPinCodeUpdateBySelf'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'

type Props = {
  teamName: string | undefined
  userName: string | undefined
  canUseAccessRequest: boolean
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}

const defaultPasswordPolicy: PasswordPolicy = {
  minimum_length: 6,
  maximum_length: 50,
  uppercase_required: false,
  lowercase_required: false,
  number_required: false,
  symbol_required: false,
  not_allow_last_password: false,
}

export const HeaderSmall: React.FC<Props> = ({
  teamName,
  userName,
  canUseAccessRequest,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  const [spMenuModalIsOpen, setSpMenuModalIsOpen] = useState(false)
  const [spAccountModalIsOpen, setSpAccountModalIsOpen] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [pinCodeModalOpen, setPinCodeModalOpen] = useState(false)
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy>(
    defaultPasswordPolicy,
  )
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [pinCode, setPinCode] = useState('')
  const [confirmPinCode, setConfirmPinCode] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPincode, setShowNewPincode] = useState(false)
  const [openSnackbar] = useSnackbar()
  const sessionTokenFromCookie = cookies.get('session_token')

  useEffect(() => {
    ;(async () => {
      try {
        const subdomain = getSubdomain(window.location.host)
        const response = await getPasswordPolicy(subdomain)
        if (response && response.password_policy) {
          setPasswordPolicy(response.password_policy)
        } else {
          console.error('Password policy not found, using default values')
          setPasswordPolicy(defaultPasswordPolicy)
        }
      } catch (error) {
        console.error('Error fetching password policy:', error)
        setPasswordPolicy(defaultPasswordPolicy)
      }
    })()
  }, [])

  // forcePasswordChange/forcePinCodeChange が true の場合、自動的にパスワード変更モーダルを開く
  useEffect(() => {
    // 意図的にpasswordを後に開く。両方とも要求される場合は、パスワードを優先する
    if (forcePinCodeChange) {
      setPinCodeModalOpen(true)
    }
    if (forcePasswordChange) {
      setPasswordModalOpen(true)
    }
  }, [forcePasswordChange, forcePinCodeChange])

  const handleClickHamburgerMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSpMenuModalIsOpen(true)
  }

  const handleClickCloseMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSpMenuModalIsOpen(false)
  }

  const handleClickAccountRow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSpAccountModalIsOpen(true)
  }

  const handleClickAccessRequestRow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    navigate('/accessRequest')
  }

  const handleClickAccessRequestApproveRow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    navigate('/accessRequestApprove')
  }

  const handleClickDeviceApprovalRequest = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    navigate('/deviceApprovalRequest')
  }

  const handleClickBackFromAccountMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSpAccountModalIsOpen(false)
  }

  const handleClickLogout = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    axios
      .get<LogoutResponse>(`${fullUrl()}/logout`, {
        params: {
          session_token: sessionTokenFromCookie || '',
        },
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        cookies.removeSessionToken()
        navigate('/login')
      })
  }

  // パスワード更新モーダル関連の関数
  const handlePasswordModalOpen = () => {
    // forcePasswordChangeがtrueの場合はユーザー操作で開く必要はない
    if (!forcePasswordChange) {
      setPasswordModalOpen(true)
    }
    setSpMenuModalIsOpen(false)
  }

  const handlePasswordModalClose = () => {
    // forcePasswordChangeがtrueなら閉じられないようにする
    if (forcePasswordChange) return
    setPasswordModalOpen(false)
  }

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value)
  }

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      openSnackbar('パスワードが一致しません')
      return
    }

    const { status } = await patchEnduserPasswordUpdateBySelf(
      sessionTokenFromCookie || '',
      newPassword,
    )
    if (status === 200) {
      openSnackbar('パスワードが更新されました')
      setNewPassword('')
      setConfirmPassword('')
      setPasswordModalOpen(false)
    } else {
      openSnackbar('パスワードの更新に失敗しました')
    }
  }

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  // PINコード更新モーダル関連の関数
  const handlePinCodeModalOpen = () => {
    // forcePinCodeChangeがtrueの場合はユーザー操作で開く必要はない
    if (!forcePinCodeChange) {
      setPinCodeModalOpen(true)
    }
    setSpMenuModalIsOpen(false)
  }

  const handlePinCodeModalClose = () => {
    // forcePinCodeChangeがtrueなら閉じられないようにする
    if (forcePinCodeChange) return
    setPinCodeModalOpen(false)
  }

  const handlePinCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 4) {
      setPinCode(event.target.value)
    }
  }

  const handleConfirmPinCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= 4) {
      setConfirmPinCode(event.target.value)
    }
  }

  const handlePinCodeSubmit = async () => {
    if (pinCode !== confirmPinCode) {
      openSnackbar('PINコードが一致しません')
      return
    }

    if (!/^\d{4}$/.test(pinCode)) {
      openSnackbar('PINコードは4桁の数字である必要があります')
      return
    }

    const { status } = await patchEnduserPinCodeUpdateBySelf(
      sessionTokenFromCookie || '',
      pinCode,
    )

    if (status === 200) {
      openSnackbar('PINコードが更新されました')
      setPinCode('')
      setConfirmPinCode('')
      setPinCodeModalOpen(false)
    } else {
      openSnackbar('PINコードの更新に失敗しました')
    }
  }

  const toggleShowNewPincode = () => {
    setShowNewPincode(!showNewPincode)
  }

  const renderSpMenuModal = () => {
    if (spMenuModalIsOpen) {
      if (spAccountModalIsOpen) {
        return (
          <div
            className="fixed top-0 left-0 z-10"
            style={{ width: '100vw', height: '100vh', background: '#E5E5E5' }}
          >
            <div
              className="pl-8 flex items-center"
              style={{
                height: 60,
                background: '#FFF',
                boxShadow: '0px 2px 6px rgba(10, 6, 17, 0.15)',
              }}
            >
              <button className="p-2" onClick={handleClickBackFromAccountMenu}>
                <svg
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5537 1.88667L8.66699 0L0.666992 8L8.66699 16L10.5537 14.1133L4.44033 8L10.5537 1.88667Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </button>
              <span
                className="ml-4"
                style={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '156.5%',
                  letterSpacing: '0.03em',
                  color: '#333333',
                }}
              >
                アカウント
              </span>
            </div>
            <div className="mt-10">
              <div
                className="my-3 pl-5"
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '156.5%',
                  letterSpacing: '0.03em',
                  color: '#4F4F4F',
                }}
              >
                所属チーム
              </div>
              <div
                className="pl-5 flex items-center"
                style={{
                  height: 43,
                  borderTop: '1px solid #BDBDBD',
                  borderBottom: '1px solid #BDBDBD',
                  background: '#FFF',
                }}
              >
                {teamName}
              </div>
            </div>
            <div className="mt-4">
              <div
                className="my-3 pl-5"
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '156.5%',
                  letterSpacing: '0.03em',
                  color: '#4F4F4F',
                }}
              >
                氏名
              </div>
              <div
                className="pl-5 flex items-center"
                style={{
                  height: 43,
                  borderTop: '1px solid #BDBDBD',
                  borderBottom: '1px solid #BDBDBD',
                  background: '#FFF',
                }}
              >
                {userName}
              </div>
            </div>
          </div>
        )
      }
      return (
        <div
          className="fixed top-0 left-0 z-10 px-6"
          style={{ width: '100vw', height: '100vh', background: '#FFF' }}
        >
          <div
            className="flex justify-between items-center py-5"
            style={{ height: 60 }}
          >
            <img
              src={LockedLogo}
              style={{
                width: 101,
                display: 'block',
              }}
            />
            <button className="p-2" onClick={handleClickCloseMenu}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.3337 2.55366L17.447 0.666992L10.0003 8.11366L2.55366 0.666992L0.666992 2.55366L8.11366 10.0003L0.666992 17.447L2.55366 19.3337L10.0003 11.887L17.447 19.3337L19.3337 17.447L11.887 10.0003L19.3337 2.55366Z"
                  fill="#828282"
                />
              </svg>
            </button>
          </div>
          <div className="" style={{ marginTop: '20px' }}>
            <div
              onClick={handleClickAccountRow}
              className="cursor-pointer"
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              アカウント
            </div>
            <div
              onClick={handleClickDeviceApprovalRequest}
              className="cursor-pointer"
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              端末の承認をリクエスト
            </div>
            {canUseAccessRequest ? (
              <>
                <div
                  onClick={handleClickAccessRequestRow}
                  className="cursor-pointer"
                  style={{
                    fontSize: '20px',
                    lineHeight: '156.5%',
                    letterSpacing: '0.03em',
                    color: '#333333',
                    marginBottom: '28px',
                  }}
                >
                  権限の申請
                </div>
                <div
                  onClick={handleClickAccessRequestApproveRow}
                  className="cursor-pointer"
                  style={{
                    fontSize: '20px',
                    lineHeight: '156.5%',
                    letterSpacing: '0.03em',
                    color: '#333333',
                    marginBottom: '28px',
                  }}
                >
                  権限の承認
                </div>
              </>
            ) : null}
            <div
              onClick={handlePasswordModalOpen}
              className="cursor-pointer"
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              パスワードを更新する
            </div>
            <div
              onClick={handlePinCodeModalOpen}
              className="cursor-pointer"
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              PINコードを更新する
            </div>
            <a
              href="https://www.notion.so/onetapincjp/LOCKED-d354ce025e0f42ff9f143ce00f0a9410"
              target="_blank"
            >
              <div
                className="flex items-center"
                style={{
                  fontSize: '20px',
                  lineHeight: '156.5%',
                  letterSpacing: '0.03em',
                  color: '#333333',
                  marginBottom: '28px',
                }}
              >
                <span className="mr-2">よくある質問</span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7778 18.2778H2.22222V2.72222H10V0.5H2.22222C0.994444 0.5 0 1.49444 0 2.72222V18.2778C0 19.5056 0.994444 20.5 2.22222 20.5H17.7778C19.0056 20.5 20 19.5056 20 18.2778V10.5H17.7778V18.2778ZM12.2222 0.5V2.72222H16.2056L5.28333 13.6444L6.85556 15.2167L17.7778 4.29444V8.27778H20V0.5H12.2222Z"
                    fill="#2D9CDB"
                  />
                </svg>
              </div>
            </a>
            <div
              className="cursor-pointer"
              onClick={handleClickLogout}
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              ログアウト
            </div>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {renderSpMenuModal()}
      <div
        className="fixed flex items-center px-6 justify-between"
        style={{
          zIndex: 2,
          height: 60,
          width: '100%',
          background: '#FFF',
          boxShadow: '0px 2px 6px rgba(10, 6, 17, 0.15)',
        }}
      >
        <img
          src={LockedLogo}
          style={{
            width: 101,
            display: 'block',
          }}
        />
        <button className="p-2" onClick={handleClickHamburgerMenu}>
          <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H24V13.3333H0V16ZM0 9.33333H24V6.66667H0V9.33333ZM0 0V2.66667H24V0H0Z"
              fill="#828282"
            />
          </svg>
        </button>
      </div>

      {/* PINコード更新モーダル */}
      <Dialog
        open={pinCodeModalOpen}
        onClose={handlePinCodeModalClose}
        disableEscapeKeyDown={forcePinCodeChange}
      >
        <DialogTitle>PINコードを更新する</DialogTitle>
        <DialogContent>
          <Typography>4桁の数字で新しいPINコードを設定できます。</Typography>
          <TextField
            label="新しいPINコード"
            variant="outlined"
            fullWidth
            value={pinCode}
            onChange={handlePinCodeChange}
            style={{ marginTop: 20 }}
            type={showNewPincode ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle pin code visibility"
                    onClick={toggleShowNewPincode}
                    edge="end"
                  >
                    {showNewPincode ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="確認用のPINコード"
            variant="outlined"
            fullWidth
            value={confirmPinCode}
            onChange={handleConfirmPinCodeChange}
            style={{ marginTop: 20 }}
            type={showNewPincode ? 'text' : 'password'}
          />
        </DialogContent>
        <DialogActions>
          {
            // forcePinCodeChange が true の場合、PINコード変更モーダルを閉じるボタンを表示しない
            !forcePinCodeChange && (
              <Button onClick={handlePinCodeModalClose} color="primary">
                キャンセル
              </Button>
            )
          }
          <Button onClick={handlePinCodeSubmit} color="primary">
            更新する
          </Button>
        </DialogActions>
      </Dialog>

      {/* パスワード更新モーダル */}
      <Dialog
        open={passwordModalOpen}
        onClose={handlePasswordModalClose}
        disableEscapeKeyDown={forcePasswordChange}
      >
        <DialogTitle>パスワードを更新する</DialogTitle>
        <DialogContent>
          <Typography>
            次のルールにしたがって、パスワードを再設定することができます。
            <br />
            <br />
            <>
              ・最小文字数： {passwordPolicy.minimum_length}文字 <br />
            </>
            <>
              ・最大文字数： {passwordPolicy.maximum_length}文字 <br />
            </>
            {passwordPolicy.lowercase_required ? (
              <>
                ・少なくとも小文字を1文字使う
                <br />
              </>
            ) : null}
            {passwordPolicy.uppercase_required ? (
              <>
                ・少なくとも大文字を1文字使う
                <br />
              </>
            ) : null}
            {passwordPolicy.number_required ? (
              <>
                ・少なくとも数字を1文字使う
                <br />
              </>
            ) : null}
            {passwordPolicy.symbol_required ? (
              <>
                ・記号を1文字以上使う（! @ # $ % ^ & * _）
                <br />
              </>
            ) : null}
            {passwordPolicy.not_allow_last_password ? (
              <>
                ・直前のパスワードは再利用できません
                <br />
              </>
            ) : null}
          </Typography>
          <TextField
            label="新しいパスワード"
            variant="outlined"
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={handleNewPasswordChange}
            style={{ marginTop: 20 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="確認用のパスワード"
            variant="outlined"
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            style={{ marginTop: 20 }}
          />
        </DialogContent>
        <DialogActions>
          {
            // forcePasswordChange が true の場合、パスワード変更モーダルを閉じるボタンを表示しない
            !forcePasswordChange && (
              <Button onClick={handlePasswordModalClose} color="primary">
                キャンセル
              </Button>
            )
          }
          <Button onClick={handlePasswordSubmit} color="primary">
            更新する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import React, { FC } from 'react'
import { AppType, AppWithSortedIndexWithIdentityExistsType } from 'types/App'
import { AppExistsSmall } from './Small'
import { AppExistsLarge } from './Large'
import { GetEnduserResponse } from 'types/ApiResponse'
import { AppIconObj } from '..'
interface Props {
  windowWidth: number
  apps: AppWithSortedIndexWithIdentityExistsType[]
  canUseAccessRequest: boolean
  teamName: string | undefined
  userName: string | undefined
  deviceId: string | undefined
  chromeExtensionInstalled: boolean
  openInNewTabWhenAccessingAppInEnduserPortal: boolean | undefined
  setApps: React.Dispatch<
    React.SetStateAction<AppWithSortedIndexWithIdentityExistsType[] | undefined>
  >
  appIconObj: AppIconObj
  handleChangeAppIconSize: (size: 'large' | 'medium' | 'small') => Promise<void>
  canSortApps: boolean
  handleChangeCanSortApps: (canSortApps: boolean) => void
  forcePasswordChange: boolean
  forcePinCodeChange: boolean
}
export const AppExists: FC<Props> = ({
  windowWidth,
  apps,
  canUseAccessRequest,
  teamName,
  userName,
  deviceId,
  chromeExtensionInstalled,
  openInNewTabWhenAccessingAppInEnduserPortal,
  setApps,
  appIconObj,
  handleChangeAppIconSize,
  canSortApps,
  handleChangeCanSortApps,
  forcePasswordChange,
  forcePinCodeChange,
}) => {
  if (windowWidth && windowWidth < 1000) {
    return (
      <AppExistsSmall
        apps={apps}
        chromeExtensionInstalled={chromeExtensionInstalled}
        teamName={teamName}
        userName={userName}
        canUseAccessRequest={canUseAccessRequest}
        deviceId={deviceId}
        openInNewTabWhenAccessingAppInEnduserPortal={
          openInNewTabWhenAccessingAppInEnduserPortal
        }
        setApps={setApps}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      />
    )
  } else {
    return (
      <AppExistsLarge
        apps={apps}
        chromeExtensionInstalled={chromeExtensionInstalled}
        teamName={teamName}
        userName={userName}
        canUseAccessRequest={canUseAccessRequest}
        deviceId={deviceId}
        openInNewTabWhenAccessingAppInEnduserPortal={
          openInNewTabWhenAccessingAppInEnduserPortal
        }
        setApps={setApps}
        appIconObj={appIconObj}
        handleChangeAppIconSize={handleChangeAppIconSize}
        canSortApps={canSortApps}
        handleChangeCanSortApps={handleChangeCanSortApps}
        forcePasswordChange={forcePasswordChange}
        forcePinCodeChange={forcePinCodeChange}
      />
    )
  }
}
